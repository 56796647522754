@import url(https://rsms.me/inter/inter.css);
@import url(https://rsms.me/inter/inter.css);
* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  font-family: 'Inter var', sans-serif;
  color: white;
  background: #101010;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
}

.bg {
  background: #101010;
}

.fullscreen.notready {
  color: #606060;
}

.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  color: #808080;
  padding: 40px;
  pointer-events: none;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
  font-size: 10px;
  line-height: 1.5em;
}

.menu.left {
  justify-content: flex-start;
}

.menu.right {
  justify-content: flex-end;
}

.menu > div {
  word-wrap: none;
  word-break: none;
  white-space: pre;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.menu > div b {
  font-weight: 600;
  color: #b0b0b0;
}

.fullscreen.ready > div {
  cursor: pointer;
}

.fullscreen.clicked {
  pointer-events: none;
  opacity: 0;
}

a {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
  color: inherit;
}

html,
body,
#root {
  position: relative;
  margin: 0;
  padding: 0;
  overflow: hidden;
  outline: none;
  width: 100vw;
  height: 100vh;
}

body {
  background: white;
  font-family: "Inter var", sans-serif;
  display: flex;
  justify-content: center;
}

#root {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  position: absolute;
  top: 43px;
  left: 180px;
  padding: 0;
  margin: 40px;
  line-height: 0.7em;
  color: #ffffff;
}

h1 {
  font-size: 5em;
}

h2 {
  font-size: 3em;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1em;
}

.picker {
  position: absolute !important;
  top: 25px;
  left: 20px;
  width: 150px !important;
  height: 150px !important;
}

.picker__swatches {
  position: absolute !important;
  top: 190px;
  left: 0px;
  width: 450px !important;
  height: 40px;
  background: transparent;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  padding: .25em;
}

.picker__swatch {
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.button {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #005985;
  color: #fefefe;
}

a {
  line-height: inherit;
  color: #005985;
  text-decoration: none;
  cursor: pointer;
}

nav {
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  z-index: 999;
  top: 25%;
}
